import router from '@/router'

// 监测是否处于登录状态
export const isLogin = () => !!localStorage.getItem('loginInfo')

// 跳转到对应的登录页面

export const toLogin = (fullPath?: string) => {
  if (process.env.VUE_APP_LOGIN_TYPE === 'sso') {
    location.href = '/sso-login.html?back=' + encodeURIComponent(location.href)
  } else {
    if (fullPath === undefined || fullPath === null) {
      console.log('[user.ts] toLogin 没有传入 fullPath 可能导致重新定向失败')
      fullPath = '/'
    }

    router.push({ path: '/login', query: { redirect: btoa(fullPath) } })
  }
}


import { defineComponent, reactive, ref } from 'vue'
import { onBeforeRouteUpdate, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import httpRequest from '@/service'
import { IFilterDataObj, IState } from './types'
import AdvancedSearch from './index3.vue'
import AdvancedCaseSearch from './index4.vue'
import CaseLibrarySearch from '../../views/Layout/CaseLibrary/caseLibrarySearch.vue'
import AdvancedLawSearch from './index5.vue'

export default defineComponent({
  name: 'SearchAll',
  components: {
    AdvancedSearch,
    AdvancedCaseSearch,
    AdvancedLawSearch,
    CaseLibrarySearch
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const curPage = ref(router.currentRoute.value.path)

    onBeforeRouteUpdate((to) => {
      curPage.value = to.path
      getFilterListData()
    })

    const filterData = ref({} as IFilterDataObj)
    const getFilterListData = () => {
      httpRequest
        .post({ url: 'filterList' })
        .then((res) => {
          filterData.value = res.data
          categoryState.getData(filterData.value)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    getFilterListData()

    // 法律法规/行政案例
    const categoryState: IState = reactive({
      id: '',
      data: [],
      getData: (filterData: IFilterDataObj) => {
        categoryState.data = filterData.categoryList
        if (curPage.value === '/SearchCaseList') {
          categoryState.id = '2'
          store.commit('setFormFlag', 1)
        } else if (curPage.value === '/SearchLawList') {
          categoryState.id = '3'
          store.commit('setFormFlag', 2)
        } else if (curPage.value.startsWith('/CaseLibrary')) {
          categoryState.id = '4'
          store.commit('setFormFlag', 3)
        } else {
          categoryState.id = '1'
          store.commit('setFormFlag', 0)
        }
      },
      handleClick: (value: string) => {
        if (categoryState.id === value) return
        categoryState.id = value
        store.commit('setForm', {})
        store.commit('setSearchList', [])
        switch (Number(categoryState.id)) {
          case 1: {
            store.commit('setFormFlag', 0)
            router.push('/SearchList')
            break
          }
          case 2: {
            store.commit('setFormFlag', 1)
            router.push('/SearchCaseList')
            break
          }
          case 3: {
            store.commit('setFormFlag', 2)
            router.push('/SearchLawList')
            break
          }
          case 4: {
            store.commit('setFormFlag', 3)
            router.push('/CaseLibrary')
            break
          }
        }
      }
    })
    const search1 = ref()
    const search2 = ref()
    const search3 = ref()
    const search4 = ref()
    function onSubmit(selectedStr){
      switch (categoryState.id) {
        case '1':
          search1.value.$refs.searchBoxRef.searchValue = selectedStr
          search1.value.$refs.searchBoxRef.searchSubmit()
          break;
        case '2':
          search2.value.$refs.searchBoxRef.searchValue = selectedStr
          search2.value.$refs.searchBoxRef.searchSubmit()
          break;
        case '4':
          search4.value.simpleSearchContent = selectedStr
          search4.value.doSimpleSearch()
          break;
        case '3':
          search3.value.$refs.searchBoxRef.searchValue = selectedStr
          search3.value.$refs.searchBoxRef.searchSubmit()
          break;
      }   
    }
    return {
      filterData,
      curPage,
      categoryState,
      onSubmit,
      search1,
      search2,
      search3,
      search4,
    }
  }
})

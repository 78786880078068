
import { ref, reactive, watch, defineComponent, PropType, nextTick } from 'vue'
import { IObj, IComObj, IFilterDataObj, IState } from '../types'
import httpRequest from '@/service'
import { ElMessage } from 'element-plus/lib/components'
import FormRadioGroup from './formRadioGroup.vue'
import { isLogin, toLogin } from '@/utils/user'
import { useRoute } from 'vue-router'
export default defineComponent({
  emits: ['submit', 'expand'],
  components: {
    FormRadioGroup
  },
  props: {
    disabledSelect:{
      type:Boolean,
      default: false
    },
    data: {
      type: Object as PropType<IFilterDataObj>,
      default: () => {
        return {}
      }
    },
    resultsList: {
      type: Array,
      default: () => {
        return []
      }
    },
    formObj: {
      type: Object as PropType<IComObj>,
      default: () => {
        return {}
      }
    }
  },
  setup(props: any, { emit }) {
    const filterData = ref(props.data)
    const lawTotal = ref(0)
    const formSearchObj = ref(props.formObj)
    const resultsStaticList = ref(props.resultsList)
    const route = useRoute();
    nextTick(() => {
      filterData.value = props.data
      lawTotal.value = filterData.value.lawTotal
      rangeState.getData(filterData.value)
      fuzzyState.getData(filterData.value)
      spaceState.getData(filterData.value)
    })
    watch(
      () => props.data,
      (newValue) => {
        filterData.value = newValue
        lawTotal.value = filterData.value.lawTotal
        rangeState.getData(filterData.value)
        fuzzyState.getData(filterData.value)
        spaceState.getData(filterData.value)
      }
    )
    watch(
      () => props.formObj,
      () => {
        formSearchObj.value = props.formObj
        filterObj.isResults = formSearchObj.value.isResults
      }
    )
    watch(
      () => props.resultsList,
      () => {
        resultsStaticList.value = props.resultsList
      }
    )

    // 普通检索参数对象
    const searchValue = ref('') // 检索标题内容
    let filterObj: IComObj = reactive({
      searchTitle: [], // 检索标题内容
      searchArtical: [], // 检索全文内容
      searchLabel: [], // 检索标签内容
      fuzzyTitleId: '', // 标题精确/模糊
      fuzzyArticalId: '', // 全文精确/模糊
      isSynonym: true,
      spaceArticalId: '', // 同篇/同条/同段/同句
      isResults: formSearchObj.value.isResults // 结果中检索
    })

    // 标题/全文
    const rangeState: IState = reactive({
      id: '',
      label: '',
      data: [],
      getData: (filterData: IFilterDataObj) => {
        rangeState.data = filterData.rangeList
        rangeState.id = rangeState.data[0].id
        rangeState.label = rangeState.data[0].label
        filterObj.rangeId = rangeState.id
      },
      handleClick: (value: any) => {
        rangeState.id = value
        filterObj.rangeId = rangeState.id
        rangeState.label = rangeState.data.filter((item) => {
          return item.id === value
        })[0].label
        if (rangeState.id === '1') {
          spaceState.getData(filterData.value)
          fuzzyState.handleClick(fuzzyState.data[0].id)
        } else {
          spaceState.id =
            filterObj.spaceArticalId =
            fuzzyState.id =
            filterObj.fuzzyArticalId =
            filterObj.fuzzyTitleId =
              ''
          filterObj.isSynonym = true
          filterObj.isResults = false
          showAdvanced(false)
        }
      }
    })
    // 标题/全文-精确/模糊
    const fuzzyState: IState = reactive({
      id: '',
      data: [],
      getData: (filterData: IFilterDataObj) => {
        fuzzyState.data = filterData.fuzzyList
        fuzzyState.id = fuzzyState.data[0].id
        filterObj.fuzzyTitleId = filterObj.fuzzyArticalId = fuzzyState.id
      },
      handleClick: (value: any) => {
        fuzzyState.id = value
        switch (rangeState.id) {
          case '0':
            filterObj.fuzzyTitleId = fuzzyState.id
            break
          case '1':
            filterObj.fuzzyArticalId = fuzzyState.id
            break
        }
      }
    })
    // 全文-同篇/同条/同段
    const spaceState: IState = reactive({
      id: '',
      data: [],
      getData: (filterData: IFilterDataObj) => {
        spaceState.data = filterData.spaceList

        if (rangeState.id === '1') {
          // 全文
          spaceState.data.forEach((o: any) => {
            o.disabled = false
          })
          spaceState.id = filterObj.spaceArticalId = spaceState.data[0].id
        } else {
          spaceState.data.forEach((o: any) => {
            o.disabled = true
          })
          spaceState.id =
            filterObj.spaceArticalId =
            filterObj.fuzzyArticalId =
              ''
        }
      },
      handleClick: (value: any) => {
        spaceState.id = value
        if (rangeState.id === '1') {
          filterObj.spaceArticalId = spaceState.id
        } else {
          filterObj.spaceArticalId = ''
        }
      }
    })

    // 输入框模糊检索
    const querySearchAsync = (queryString: string, cb: (arg: any) => void) => {
      const searchPart = document.querySelector('.search-part')
      if (searchPart) {
        searchPart.classList.add('hidden-pop')
        if (
          searchValue.value !== '' &&
          searchValue.value.length !== 1
        ) {
          httpRequest
            .post({
              url: 'searchList',
              params: {
                name: queryString,
                spaceFlag: rangeState.id,
                fuzzyTitleId: fuzzyState.id
              }
            })
            .then((res) => {
              if (!res.data.length) {
                searchValue.value = queryString
                cb([])
              } else {
                cb(res.data)
                searchPart.classList.remove('hidden-pop')
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }
    const handleSelect = (item: any) => {
      searchValue.value = item.textName
      if (rangeState.id === '1') {
        searchSubmit()
      } else {
        searchValue.value = ''
      }
    }
    // 获取焦点时判断是否需要隐藏模糊检索列表
    const handleFocus = () => {
      const searchPart = document.querySelector('.search-part')
      if (searchPart&&!searchValue.value) {
        searchPart.classList.add('hidden-pop')
      }
    }
    // 递归删除所有全文/标题
    const deleteFn = (arr: IObj[], rangeType: string) => {
      const keyIndex = arr.findIndex((i: IObj) => rangeType === i.type)
      if (keyIndex > -1) {
        arr.splice(keyIndex, 1)
        deleteFn(arr, rangeType)
      }
    }
    // 提交

    const searchSubmit = () => {
      if(!isLogin()) return toLogin(route.fullPath)

      if (!searchValue.value) {
        ElMessage({
          message: '请输入检索词',
          type: 'warning'
        })
      } else {
        const searchPart = document.querySelector('.search-part')
        if (searchPart) {
          searchPart.classList.add('hidden-pop')
        }
        const ranges = new Map([
          ['0', ['标题：', 'searchTitle', ['searchArtical', 'searchLabel']]],
          ['1', ['全文：', 'searchArtical', ['searchTitle', 'searchLabel']]],
          // ['2', ['标签：', 'searchLabel', ['searchTitle', 'searchArtical']]]
        ])
        let rangeFlag = ranges.get(rangeState.id)
        const rangeKey = rangeFlag && rangeFlag[0]
        const rangeType = rangeFlag && rangeFlag[1]
        const rangeTypeElse = rangeFlag && rangeFlag[2]
        const idFlag = resultsStaticList.value.some((i: IObj) => {
          if (i.id === searchValue.value && i.type === rangeType) {
            return true
          }
        })
        if (idFlag) return
        deleteFn(resultsStaticList.value, 'searchLabel')
        filterObj['searchLabel'] = []

        if (filterObj.isResults) {
          filterObj[rangeType as string].push(searchValue.value)
        } else {
          ;(rangeTypeElse as string[]).forEach((type: string) => {
            deleteFn(resultsStaticList.value, type)
            filterObj[type] = []
          })
          deleteFn(resultsStaticList.value, rangeType as string)
          filterObj[rangeType as string] = [searchValue.value]
        }
        resultsStaticList.value.push({
          type: rangeType,
          name: rangeKey + searchValue.value,
          id: searchValue.value
        })
        formSearchObj.value = { ...formSearchObj.value, ...filterObj }
        emit('submit', formSearchObj.value, resultsStaticList.value)
        searchValue.value = ''
      }
    }
    // 展开高级检索
    const showAdvanced = (flag: boolean) => {
      if (flag) {
        emit('expand', flag)
      } else {
        emit('expand')
      }
    }

    return {
      searchValue,
      filterObj,
      lawTotal,
      rangeState,
      fuzzyState,
      spaceState,
      querySearchAsync,
      handleSelect,
      handleFocus,
      searchSubmit,
      showAdvanced
    }
  }
})

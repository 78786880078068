import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-12a081f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category-part" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_advanced_search = _resolveComponent("advanced-search")!
  const _component_advanced_case_search = _resolveComponent("advanced-case-search")!
  const _component_case_library_search = _resolveComponent("case-library-search")!
  const _component_advanced_law_search = _resolveComponent("advanced-law-search")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryState.data, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass({ active: _ctx.categoryState.id === item.id }),
          onClick: ($event: any) => (_ctx.categoryState.handleClick(item.id))
        }, [
          _createElementVNode("span", null, _toDisplayString(item.name), 1 /* TEXT */)
        ], 10 /* CLASS, PROPS */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    (_ctx.categoryState.id === '1')
      ? (_openBlock(), _createBlock(_component_advanced_search, {
          key: 0,
          ref: "search1",
          filterData: _ctx.filterData
        }, null, 8 /* PROPS */, ["filterData"]))
      : _createCommentVNode("v-if", true),
    (_ctx.categoryState.id === '2')
      ? (_openBlock(), _createBlock(_component_advanced_case_search, {
          key: 1,
          ref: "search2",
          filterData: _ctx.filterData
        }, null, 8 /* PROPS */, ["filterData"]))
      : _createCommentVNode("v-if", true),
    (_ctx.categoryState.id === '4')
      ? (_openBlock(), _createBlock(_component_case_library_search, {
          key: 2,
          ref: "search4",
          filterData: _ctx.filterData
        }, null, 8 /* PROPS */, ["filterData"]))
      : _createCommentVNode("v-if", true),
    (_ctx.categoryState.id === '3')
      ? (_openBlock(), _createBlock(_component_advanced_law_search, {
          key: 3,
          ref: "search3",
          filterData: _ctx.filterData
        }, null, 8 /* PROPS */, ["filterData"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}
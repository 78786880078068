
import { defineComponent, nextTick, reactive, ref, watch } from 'vue'
import { CaretTop } from '@element-plus/icons-vue'
import httpRequest from '@/service'
import { IAdvObj, IComObj, IObj } from '../types'
import FormCascader from './formCascader.vue'
import FormSelect from './formSelect.vue'
import FormDateBreak from './formDateBreak.vue'
import FormRadioGroup from './formRadioGroup.vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  emits: ['reset', 'submit'],
  components: {
    CaretTop,
    FormCascader,
    FormSelect,
    FormDateBreak,
    FormRadioGroup
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup(props: any, { emit }) {
    const resultsListStatic = ref<IObj[]>([]) // 高级检索暂存检索条件

    nextTick(() => {
      titleState.getFuzzyData(props.data)
      articalState.getSpaceData(props.data)
      articalState.getFuzzyData(props.data)
    })

    watch(
      () => props.data,
      (newData) => {
        titleState.getFuzzyData(newData)
        articalState.getSpaceData(newData)
        articalState.getFuzzyData(newData)
      }
    )

    // 检索条件添加：在添加前需要先判断是否已有条件
    const setResultListStatic = (e: any, state: any) => {
      resultsListStatic.value.forEach((item: any, index: number) => {
        if (item.type === state.key) {
          resultsListStatic.value.splice(index, 1)
        }
      })
      if (state.type === 'input') {
        resultsListStatic.value.push({
          type: state.key,
          name: state.title + '：' + e,
          id: e
        })
      } else if (state.type === 'select') {
        resultsListStatic.value.push({
          type: state.key,
          name: state.title + '：' + e.name,
          id: e.id
        })
      } else if (state.type === 'cascader') {
        resultsListStatic.value.push({
          type: state.key,
          name: state.title + '：' + e.label,
          id: e.id
        })
      } else if (state.type === 'date') {
        resultsListStatic.value.push({
          type: state.key,
          name: state.title + '：' + e[0] + '至' + e[1],
          id: e
        })
      }
    }
    // 检索重置，取消
    const resetForm = (e: any, value = '') => {
      Object.keys(advSearchObj).forEach((key: any) => {
        if (key === titleState.fuzzyKey || key === articalState.fuzzyKey) {
          advSearchObj[key] =
            titleState.fuzzyValue =
              articalState.fuzzyValue =
                props.data.fuzzyList[0].id
        } else if (key === articalState.spaceKey) {
          advSearchObj[key] = articalState.spaceValue =
            props.data.spaceList[0].id
        } else if (key === releaseDateState.key) {
          advSearchObj[releaseDateState.key + 'Start'] = ''
          advSearchObj[releaseDateState.key + 'End'] = ''
        } else if (key === implementDateState.key) {
          advSearchObj[implementDateState.key + 'Start'] = ''
          advSearchObj[implementDateState.key + 'End'] = ''
        } else {
          advSearchObj[key] = ''
        }
      })
      titleState.value = ''
      articalState.value = ''
      institutionState.value = ''
      timelinessState.value = []
      effectState.value = []
      postState.value = ''
      releaseDateState.start = ''
      releaseDateState.end = ''
      implementDateState.start = ''
      implementDateState.end = ''
      resultsListStatic.value = []

      industryState.value = []
      thematicState.value = []
      advSearchObj.thematics = []
      advSearchObj.industries = []

      value && emit('reset')
    }
    // 提交
    const submitForm = () => {
      emit('submit', advSearchObj, resultsListStatic.value)
      sessionStorage.setItem('institutionParent', institutionState.ptArr)
      resetForm('', 'cancel')
      resultsListStatic.value = []
    }

    // 时效性
    const timelinessState = reactive<any>({
      title: '时效性',
      type: 'select',
      key: 'timelinessIds',
      data: [],
      value: [],
      getData() {
        return httpRequest
          .post({ url: 'timelinessList' })
          .then((res: any) => {
            timelinessState.data = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleClick(obj: any) {
        advSearchObj.timelinessIds = obj
        setResultListStatic({
            name: advSearchObj.timelinessIds
              .map((it: string) => timelinessState.data.find((o: any) => o.id === it))
              .filter((it: any) => it)
              .map((it: any) => it.name)
              .join('、')
          },
          timelinessState
        )
      }
    })

    // 专题分类
    const thematicState = reactive({
      title: '专题分类',
      type: 'select',
      key: 'thematics',
      origin: [],
      data: [],
      value: [],
      getData() {
        httpRequest
          .post({
            url: 'listDictionaryBatch',
            params: { name: 'THEMATIC' },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          })
          .then((res: any) => {
            thematicState.origin = res.data['THEMATIC']
            thematicState.data = res.data['THEMATIC'].map((el: { dmabr1: any; dmcod: any; id: any }) => ({
              id: el.dmcod,
              name: el.dmabr1,
              value: el.dmcod
            }))
          })
          .catch((err) => {
            console.error(err)
          })
      },
      handleClick(obj: any) {
        advSearchObj.thematics = obj
        setResultListStatic({
            name: advSearchObj.thematics
              .map((it: string) => thematicState.origin.find((o: any) => o.dmcod === it))
              .filter((it: any) => it)
              .map((it: any) => it.dmcpt)
              .join('、')
          },
          thematicState
        )
      }
    })
    thematicState.getData()

    function handleTree(data: any) {
      // function handleTree(data: any, id: string, parentId: string, children: string, rootId: any) {
      let id = 'id'
      let parentId = 'parentid'
      let children = 'children'
      let rootId = Math.min.apply(Math, data.map((item: any) => {
        return item[parentId]
      })) || '0'
      const cloneData = JSON.parse(JSON.stringify(data))
      const treeData = cloneData.filter((father: any) => {
        const branchArr = cloneData.filter((child: any) => {
          return father[id] === child[parentId]
        })
        if (branchArr.length > 0) {
          father.children = branchArr
        }
        return father[parentId] === '0'
      })
      return treeData !== '' ? treeData : data
    }

    // 法规分类
    const industryState = reactive({
      title: '法规分类',
      type: 'cascader',
      key: 'industries',
      origin: [],
      data: [],
      value: [],
      getData() {
        httpRequest
          .post({
            url: 'listDictionaryBatch',
            params: { name: 'INDUSTRY' },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          })
          .then((res: any) => {
            industryState.origin = res.data['INDUSTRY']
            industryState.data = handleTree(res.data['INDUSTRY'])
          })
          .catch((err) => {
            console.error(err)
          })
      },
      handleClick(obj: any) {
        advSearchObj.industries = obj.flatMap((it: any) => it[it.length - 1])
        setResultListStatic({
            label: advSearchObj.industries
              .map((it: string) => industryState.origin.find((o: any) => o.dmcod === it))
              .filter((it: any) => it)
              .map((it: any) => it.dmcpt)
              .join('、')
          },
          industryState
        )
      }
    })
    industryState.getData()

    // 效力级别
    const effectState: { [key: string]: any } = reactive({
      title: '效力级别',
      type: 'select',
      key: 'effectIds',
      data: [] as any[],
      originData: [] as any[],
      value: [],
      getData() {
        return httpRequest
          .post({ url: 'effectLevelList' })
          .then((res: any) => {
            this.originData = res.data
            let datas: any[] = res.data
            let childrenDataMap: { [key: string]: any[] } = {}
            datas.forEach(item => {
              if (item.parentId) {
                childrenDataMap[item.parentId] = childrenDataMap[item.parentId] || []
                childrenDataMap[item.parentId].push(item)
              } else {
                childrenDataMap.root = childrenDataMap.root || []
                childrenDataMap.root.push(item)
              }
              // if (item.id.length === 4) {
              //   let pid = item.id.substring(0, 2)
              //   childrenDataMap[pid] = childrenDataMap[pid] || []
              //   childrenDataMap[pid].push(item)
              // } else if (item.id.length === 2) {
              //   childrenDataMap.root = childrenDataMap.root || []
              //   childrenDataMap.root.push(item)
              // }
            })
            this.data = datas.filter(item => {
              item.leaf = !childrenDataMap[item.id]
              item.children = childrenDataMap[item.id]
              return !item.parentId
            })
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleClick(obj: any) {
        advSearchObj.effectIds = obj
        setResultListStatic({
            name: advSearchObj.effectIds
              .map((it: string) => effectState.originData.find((o: any) => o.id === it))
              .filter((it: any) => it)
              .map((it: any) => it.name)
              .join('、')
          },
          effectState
        )
      }
    })
    

    // 标题
    const titleState = reactive({
      title: '标题',
      type: 'input',
      key: 'searchTitle',
      value: '',
      handleClick(value: string) {
        advSearchObj.searchTitle = titleState.value = value
        setResultListStatic(value, titleState)
      },
      // 标题精确/模糊
      fuzzyKey: 'fuzzyTitleId',
      fuzzyData: [] as IObj[],
      fuzzyValue: '',
      getFuzzyData(data: IComObj) {
        titleState.fuzzyData = data.fuzzyList
        advSearchObj.fuzzyTitleId = titleState.fuzzyValue = titleState
          .fuzzyData[0].id as string
      },
      handleFuzzy(obj: any) {
        titleState.fuzzyValue = obj.name
        advSearchObj.fuzzyTitleId = obj.id
      }
    })
    // 全文
    const articalState = reactive({
      title: '全文',
      type: 'input',
      key: 'searchArtical',
      value: '',
      handleClick(value: string) {
        advSearchObj.searchArtical = articalState.value = value
        setResultListStatic(value, articalState)
      },

      fuzzyKey: 'fuzzyArticalId', // 全文精确/模糊
      fuzzyData: [] as IObj[],
      fuzzyValue: '',
      getFuzzyData(data: IComObj) {
        articalState.fuzzyData = data.fuzzyList
        advSearchObj.fuzzyArticalId = articalState.fuzzyValue = articalState
          .fuzzyData[0].id as string
      },
      handleFuzzy(obj: any) {
        articalState.fuzzyValue = obj.name
        advSearchObj.fuzzyArticalId = obj.id
      },

      spaceKey: 'spaceArticalId', // 全文同篇/同条/同段/同句
      spaceData: [] as IObj[],
      spaceValue: '',
      getSpaceData(data: IComObj) {
        articalState.spaceData = JSON.parse(JSON.stringify(data.spaceList))
        articalState.spaceData.forEach((item: any) => {
          item.disabled = false
        })
        advSearchObj.spaceArticalId = articalState.spaceValue = articalState
          .spaceData[0].id as string
      },
      handleSpace(value: any) {
        advSearchObj.spaceArticalId = articalState.spaceValue = value
      }
    })
    // 发文字号
    const postState = reactive({
      title: '发文字号',
      type: 'input',
      key: 'postValue',
      value: '',
      handleClick(value: string) {
        advSearchObj.postValue = postState.value = value
        setResultListStatic(value, postState)
      }
    })
    // 发布机关（新需求改为输入框）
    const institutionState = reactive({
      title: '发布机关',
      type: 'input',
      key: 'pubOrgName',
      value: '',
      ptArr: '',
      handleClick(value) {
        advSearchObj.pubOrgName = institutionState.value = value
        setResultListStatic(value, institutionState)
      }
    })
    // 发布机关（原来的树形下拉框）
    // const institutionState = reactive({
    //   title: '发布机关',
    //   type: 'cascader',
    //   key: 'institutionId',
    //   value: '',
    //   level: '',
    //   ptArr: '',
    //   handleClick(obj: any, ptArr: string[]) {
    //     advSearchObj.institutionId = institutionState.value = obj.id
    //     advSearchObj.institutionLevel = institutionState.level = String(
    //       obj.level
    //     )
    //     institutionState.ptArr = JSON.stringify(ptArr)
    //     setResultListStatic(obj, institutionState)
    //   }
    // })
    // 发布日期
    const releaseDateState = reactive({
      title: '发布日期',
      type: 'date',
      key: 'releaseDate',
      start: '',
      end: '',
      handleClick(date: any) {
        advSearchObj.releaseDateStart = releaseDateState.start = date[0]
        advSearchObj.releaseDateEnd = releaseDateState.end = date[1]
        setResultListStatic(date, releaseDateState)
      }
    })
    // 实施日期
    const implementDateState = reactive({
      title: '实施日期',
      type: 'date',
      key: 'implementDate',
      start: '',
      end: '',
      handleClick(date: any) {
        advSearchObj.implementDateStart = implementDateState.start = date[0]
        advSearchObj.implementDateEnd = implementDateState.end = date[1]
        setResultListStatic(date, implementDateState)
      }
    })

    // 高级检索参数对象
    const advSearchObj: IAdvObj = reactive({
      title: '',//标题
      searchTitle: '', // 检索标题内容
      searchArtical: '', // 检索全文内容
      fuzzyTitleId: '', // 标题精确/模糊
      fuzzyArticalId: '', // 全文精确/模糊
      spaceArticalId: '', // 全文同篇/同条/同段/同句
      pubOrgName: '',// 发布机关输入框
      institutionId: '', // 发布机关
      institutionLevel: '', // 发布机关
      institutionParent: [], //发布机关
      postValue: '', // 发文字号
      timelinessId: '', // 时效性
      effectId: '', // 效力级别
      releaseDateStart: '', // 发布日期
      releaseDateEnd: '', // 发布日期
      implementDateStart: '', // 实施日期
      implementDateEnd: '', // 实施日期
      effectIds: [],//效力级别
      timelinessIds: [],//时效性
      thematics: [],//专题分类
      industries: []//法规分类
    })
    // 详情页属性超链接点过来（难受）
    const route = useRoute()
    let { name,field,value } = route.query
    Promise.all([timelinessState.getData(),effectState.getData()]).then(() => {
      if (name && field && value) {
        if(field == 'timeliness'){
          const code = timelinessState.data.find((item)=>item.name == value)?.id
          timelinessState.handleClick([code])
        }
        if(field == 'effectLevel') {
          const code = effectState.data.find((item)=>item.name == value)?.id
          effectState.handleClick([code])
        }
        if(field == 'searchArtical') {
          articalState.handleClick(value as string)
        }
        submitForm()
      }
    })
    return {
      advSearchObj,
      titleState,
      articalState,
      institutionState,
      timelinessState,
      thematicState,
      industryState,
      effectState,
      postState,
      releaseDateState,
      implementDateState,
      resetForm,
      submitForm
    }
  }
})

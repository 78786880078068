
import { ref, reactive, watch, defineComponent, PropType, nextTick } from 'vue'
import { useRouter,useRoute } from 'vue-router'
import { IObj, IComObj, IFilterDataObj, IState } from '../types'
import httpRequest from '@/service'
import { ElMessage } from 'element-plus/lib/components'
import { isLogin, toLogin } from '@/utils/user'

export default defineComponent({
  emits: ['submit', 'expand'],
  props: {
    data: {
      type: Object as PropType<IFilterDataObj>,
      default: () => {
        return {}
      }
    },
    resultsList: {
      type: Array,
      default: () => {
        return []
      }
    },
    formObj: {
      type: Object as PropType<IComObj>,
      default: () => {
        return {}
      }
    }
  },
  setup(props: any, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const curPage = ref(router.currentRoute.value.fullPath)
    const filterData = ref(props.data)

    const lawTotal = ref(0)
    const formSearchObj = ref(props.formObj)
    const resultsStaticList = ref(props.resultsList)
    nextTick(() => {
      rangeCaseState.getData(filterData.value)
    })
    watch(
      () => props.data,
      (newValue) => {
        filterData.value = newValue
        lawTotal.value = filterData.value.lawTotal
        rangeCaseState.getData(filterData.value)
        filterObj.rangeId = rangeCaseState.id
      }
    )
    watch(
      () => props.formObj,
      () => {
        formSearchObj.value = props.formObj
        filterObj.isResults = formSearchObj.value.isResults
      }
    )
    watch(
      () => props.resultsList,
      () => {
        resultsStaticList.value = props.resultsList
      }
    )
    // watch(
    //   () => props.formObj.categoryId,
    //   (newValue, oldValue) => {
    //     // if (!oldValue) return
    //     rangeCaseState.getData(filterData.value)
    //     filterObj.rangeId = rangeCaseState.id
    //   }
    // )

    // 普通检索参数对象
    const searchValue = ref('') // 检索标题内容
    let filterObj: IComObj = reactive({
      rangeId: '',
      searchCase: [] // 检索标签内容
    })

    // 案件名称/执法主体
    const rangeCaseState: IState = reactive({
      id: '',
      label: '',
      data: [],
      getData: (filterData: IFilterDataObj) => {
        rangeCaseState.data = filterData.rangeCaseList
        rangeCaseState.id = rangeCaseState.data[0].id
        rangeCaseState.label = rangeCaseState.data[0].label
        filterObj.rangeId = rangeCaseState.id

        let { value } = route.query
        if(value){
          searchValue.value = value as string
          searchSubmit()
        }
      },
      handleClick: (value: any) => {
        rangeCaseState.id = value
        filterObj.rangeId = rangeCaseState.id
        rangeCaseState.label = rangeCaseState.data.filter((item) => {
          return item.id === value
        })[0].label
      }
    })

    // 递归删除所有全文/标题
    const deleteFn = (arr: IObj[], rangeType: string) => {
      const keyIndex = arr.findIndex((i: IObj) => rangeType === i.type)
      if (keyIndex > -1) {
        arr.splice(keyIndex, 1)
        deleteFn(arr, rangeType)
      }
    }

    const searchSubmit = () => {
      if(!isLogin()) return toLogin(route.fullPath)

      if (!searchValue.value) {
        ElMessage({
          message: '请输入检索词',
          type: 'warning'
        })
      } else {
        const ranges = new Map([
          ['3', ['案件名称：']],
          ['4', ['执法主体：']]
        ])

        deleteFn(resultsStaticList.value, 'searchCase')
        filterObj['searchCase'] = [searchValue.value]
        let rangeFlag = ranges.get(rangeCaseState.id)
        const rangeKey = rangeFlag && rangeFlag[0]
        resultsStaticList.value.push({
          type: 'searchCase',
          name: rangeKey + searchValue.value,
          id: searchValue.value
        })
        formSearchObj.value = { ...formSearchObj.value, ...filterObj }
        emit('submit', formSearchObj.value, resultsStaticList.value)
        searchValue.value = ''
      }
    }
    return {
      searchValue,
      filterObj,
      lawTotal,
      rangeCaseState,
      searchSubmit
    }
  }
})
